#participantDetails .mdc-list {
    max-height: 350px;
    overflow: auto; }
#participantDetails .mdc-list .mdc-list-item.active {
    animation-name: backgroundActive;
    animation-duration: 0.65s;
    animation-fill-mode: both; }
#participantDetails .mdc-list .mdc-list-item {
    animation-name: backgroundInactive;
    animation-duration: 0.65s;
    animation-fill-mode: both;
    cursor: pointer; }
#participantDetails .mdc-list .mdc-list-item__meta {
    font-style: inherit;
    color: #1e487f;
    font-weight: 500;
    font-size: 14px;
    font-family: "Roboto", sans-serif; }
#participantDetails .mdc-list .mdc-list-item__text {
    font-family: "Raleway", sans-serif;
    font-weight: 700; }
#participantDetails .mdc-list .mdc-list-item__secondary-text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400; }

@keyframes backgroundActive {
    from {
        background: inherit; }
    to {
        background: #ffb100; } }

@keyframes backgroundInactive {
    to {
        background: inherit; }
    from {
        background: #ffb100; } }

#participantDetails .authorizedUser .mdc-list-item__meta {
    color: #a92d25; }
